import React from 'react'
import {Link} from 'gatsby';
// import { communitiesData } from './FeaturedCommunitiesData';
// import { StaticImage } from 'gatsby-plugin-image';
import { AllCommunitiesData } from '../CommunityTemplate/CommunitiesData';
const FeaturedCommunities = () => {
  return (
    <div className="pt-[136px] pb-[89px] md:pt-[180px] sm:pt-[30px] sm:pb-[10px]">
      <div className="container">
        <div className="mb-[72px]">
          <h1 className="font-IbarraRealNova font-[600] text-d-3xl pb-[24px] sm:text-sm-3xl sm:pb-[10px]">
            Featured{' '}
            <span className="font-Quicksand font-[500] text-ms-4xl sm:text-sm-3xl">
              {' '}
              Communities
            </span>
          </h1>
          <p className="font-[500] text-[#666666] text-d-3lg font-Quicksand">
            San Diego is known for its diverse mix of neighborhoods—each of
            which <br className="2xs:hidden" />
            has a style and personality all its own.
          </p>
        </div>
        <div className="flex flex-wrap gap-[16px]">
          {AllCommunitiesData?.map((value) => (
            <div className="relative communitiesCard">
              <Link to={`${value.id}`}>
              <div className="bg-[#252525] h-[100%]">
                <img
                  src={value.img}
                  alt="communityimg"
                  className="w-[100%] h-[100%] opacity-70"
                />
                <p className="relative pl-[32px] bottom-[40px] font-Quicksand font-[700] text-d-4lg text-white">
                  {value.name}
                </p>
              </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturedCommunities
