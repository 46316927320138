import React from 'react'
import MoveYourFamily from '../Home/MoveYourFamily/MoveYourFamily';
import FeaturedCommunities from './FeaturedCommunities';
const Communities = () => {
  return (
    <>
    <FeaturedCommunities />
      <MoveYourFamily />
    </>
  )
}

export default Communities