import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import CommunityTemplate from "../component/CommunityTemplate";
import Communities from "../component/FeaturedCommunities";
const Community = () => {
  return (
    <LayoutWrapper
      title="San Diego Communities | Houses for Sale | The Selby Team"
      desc="Find the San Diego community that fits your family's lifestyle, then search listings from The Selby Team for the best homes for sale in that neighborhood."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <Communities />
    </LayoutWrapper>
  );
};

export default Community;
